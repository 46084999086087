<template>
  <div class="app-container">
    <newAccTotal/>
    <accEquityTotal/>
    <accPlTotal/>
    <depwitTotal/>
  </div>
</template>

<script>
import newAccTotal from './newAccTotal/index'
import accEquityTotal from './accEquityTotal/index'
import accPlTotal from './accPlTotal/index'
import depwitTotal from './depwitTotal/index'


export default {
  name:'report',
  components: { newAccTotal,accEquityTotal,accPlTotal,depwitTotal},
}
</script>

<style scoped>

</style>
