<template>
  <div class="app-container" style="width:100%">
    <div class="blod">出入金统计</div>
    <eHeader :query="query" />
    <!--表格渲染-->
    <el-row style="margin-top:40px;">
      <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="chart-outer">
          <baseLineChart ref="chart" :chart-config="chartConfig"></baseLineChart>
        </div>
      </el-col>
      <el-col style="margin-top:15px;" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <el-row >
            <el-col class='total_col' :span="16">总入金($)</el-col>
            <el-col class='total_col' :span="8"> {{totalData.goldDepositSum}}</el-col>
          </el-row>
          <el-row >
            <el-col class='total_col' :span="16">总IB内转($)</el-col>
            <el-col class='total_col' :span="8"> {{totalData.transferSum}}</el-col>
          </el-row>
          <el-row >
            <el-col class='total_col' :span="16">总出金($)</el-col>
            <el-col class='total_col' :span="8"> {{totalData.goldOutSum}}</el-col>
          </el-row>
          <el-row >
            <el-col class='total_col' :span="16">净入金($)</el-col>
            <el-col class='total_col' :span="8"> {{totalData.netIncome}}</el-col>
          </el-row>
           <el-row >
            <el-col class='total_col' :span="16">总出佣($)</el-col>
            <el-col class='total_col' :span="8"> {{totalData.commissionOutSum}}</el-col>
          </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { initData } from '@/api/data'
import { parseTime,parseDate } from '@/utils/index'
import baseLineChart from '@/components/echarts/baseChart'
import eHeader from '@/components/stat/report/depwitTotal/header'
export default {
  components: { eHeader,baseLineChart },
  props:{
  },
  data() {
    return {
      sup_this: this,
      data: [],
      url: '',
      params: {},
      query: {},
      totalData:{
        commissionOutSum: 0,
        goldDepositSum: 0,
        goldOutSum: 0,
        netIncome: 0,
        transferSum: 0
      },

      chartConfig : {
           tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            legend: {
                data: ['出金', '出佣', 'IB内转', '入金',]
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '10%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: []
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: '出金',
                    type: 'bar',
                    data: []
                },
                {
                    name: '出佣',
                    type: 'bar',
                    data: []
                },
                {
                    name: 'IB内转',
                    type: 'bar',
                    data: []
                },
                {
                    name: '入金',
                    type: 'bar',
                    data: []
                }]
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseDate,
    parseTime,
    beforeInit() {
      this.url = '/crm/queryStatistics'
      this.params = { queryType:4}
      const query = this.query
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      return true
    },
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      return new Promise((resolve, reject) => {
        initData(this.url, this.params).then(res => {
          this.totalData = res.statistics
          this.data = res.content
          let xAxisData=[]
          let seriesData0=[]
          let seriesData1=[]
          let seriesData2=[]
          let seriesData3=[]
          this.data.forEach(element => {
            xAxisData.push(this.parseDate(element.dateTime))
            seriesData0.push(element.goldOut)
            seriesData1.push(element.commissionOut)
            seriesData2.push(element.transfer)
            seriesData3.push(element.goldDeposit)
          });
          this.chartConfig.xAxis[0].data = xAxisData;
          this.chartConfig.series[1].data = seriesData1;
          this.chartConfig.series[0].data = seriesData0;
          this.chartConfig.series[2].data = seriesData2;
          this.chartConfig.series[3].data = seriesData3;
          this.$refs.chart.set();
        }).catch(err => {
        })
      })
    },
  }
}
</script>

<style scoped>
  .chart-outer {
      width: 835px;
      height: 306px;
      margin-bottom: 100px;
    }
  .blod {
    font-weight: 600;
    font-size: 22px;
  }
  .total_col {
    height: 50px;
    border: 1px solid ;
    text-align: center;
    line-height: 50px;
    margin:0px -1px -1px 0px;
  }
</style>
